import { Order } from "./Order";
import { OrderBasics } from "./OrderBasics";

export class Campaign extends OrderBasics {
    orderIds: Order["id"][];

    constructor(initialValues?: Partial<Campaign>) {
        super(initialValues);
        this.orderIds = initialValues?.orderIds ?? [];
    }
}
